//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BillingMixin from "@/components/Billing/Billing.mixin";
export default {
  mixins: [BillingMixin],
  props: {
    order: {
      type: Object,
      required: true
    },
    code: {
      type: String,
      required: true
    }
  },
  data() {
    const paymentMethods = this.getPaymentMethods();
    return {
      loading: false,
      paymentMethods,
      paymentMethod: paymentMethods[0].value
    };
  },
  methods: {
    getId(id) {
      return `${this._uid}-${id}`;
    },
    async onSubmit() {
      const body = {
        code: this.code,
        paymentMethod: this.paymentMethod
      };
      if (this.paymentMethod === "bank_invoice") {
        let organization = await this.$store.dispatch("billing/getCurrentOrganization").catch(e => {
          var _e$response, _e$response2;
          return ((_e$response = e.response) === null || _e$response === void 0 ? void 0 : _e$response.status) === 404 || ((_e$response2 = e.response) === null || _e$response2 === void 0 ? void 0 : _e$response2.status) === 401 ? null : Promise.reject(e);
        });
        if (!organization) {
          organization = await this.$modal.process(() => import("@/components/Billing/ModalCustomerOrganization"), {});
        }
        body.organization = organization;
      }
      const data = await this.$store.dispatch("billing/payManualOrder", body);
      await this.handlePaymentResult(data);
      this.$emit("complete");
    }
  }
};